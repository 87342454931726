import React, { useEffect, useState } from "react";

// others
import { Row, Col, Space, Button, message, Typography } from "antd";
import { Popup, PopupConfirm } from "../../../atoms/Popup";
import { useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getBookingDetail,
  sendContractForSign,
  updateBookingStatus,
  updateBooking
} from "../ducks/actions";
import { sendTenantInvite } from "../../BookingListing/ducks/actions";
import {
  CreateEjariContract,
  sendContractForSignService,
  updatePdcService,
} from "../ducks/services";
import RejectPopup from "./modalContent/rejectPopup";
import MoveOutPopup from "./modalContent/MoveOutPopup";
import AddContractNumber from "./modalContent/AddContractNumber";
import { LOGIN } from "../../../../configs/routeNames";
import { validateChecks } from "./utils";

interface IProps {
  handleEditPressEvent(arg0: string,arg1:Boolean): void;
  renewal: boolean;
  setLoading?: (val?: boolean) => void;
  setRenewalFormConfirm?:any;
}

interface actionType {
  label?: string;
  type?: "delete" | "accept" | "reject";
  action?: () => void;
  visible?: boolean;
  disabled?: boolean;
 
}

const loginUserId = JSON.parse(
  window.localStorage.getItem("login-response")
)?.userId;

const index = (props: IProps) => {
  const { Title } = Typography;
  const dispatch: any = useDispatch();

  // redux states
  const { bookingDetails } = useSelector((state: any) => state.newBooking);
  const { state } = useLocation();


  //ADDED FOR BUG: 8137
  const getUserForLease = () => {
    if (
      bookingDetails?.leaseManager &&
      loginUserId == bookingDetails?.leaseManager?.userId &&
      LOGIN_USER == "managersManager"
    )
      return "landlord"; //grants landlord permission in this case
  };

  const { leaseId = bookingDetails.leaseId } = useParams();
  const [modalvisible, setModalvisible] = useState(false);
  const [modalTrigger, setModalTrigger] = useState(0);
  const [LOGIN_USER, setLoginUser] = useState(
    bookingDetails?.loginUserType || ""
  );
  const [LEASE_USER, setLeaseUser] = useState(getUserForLease());

  const loginUserType = (users = []) =>
    !!users?.includes(LEASE_USER || LOGIN_USER); //ADDED FOR BUG: 8137

  useEffect(() => {
    //ADDED TO SOLVE BUG FOR LOGINUSERTYPE NOT COMING IN RESP OF UPDATEAPIS
    bookingDetails?.loginUserType &&
      setLoginUser(bookingDetails?.loginUserType);
  }, [bookingDetails?.loginUserType]);

  useEffect(() => {
    bookingDetails?.leaseManager && setLeaseUser(getUserForLease());
  }, [loginUserId, LOGIN_USER]);

  const onStatusBookingForm = async (body) => {
    props?.setLoading(true);
    try {
      await dispatch(updateBookingStatus({ leaseId, body }));
      message.success("Successfully updated");
      setTimeout(async () => {
        await dispatch(getBookingDetail(bookingDetails.leaseId)); //FIXED ONCE FOR ALL
      }, 400);
      props?.setLoading(false);
    } catch (error) {
      message.error("failed to updated");
      props?.setLoading(false);
    }
  };

  const onCancelBook = async (data?: any) => {
    let body = { comments: data?.comments, status: "booking_reject" };
    await onStatusBookingForm(body);
    setModalvisible(false);

  };

  //Api call for email send and status update
  const sendToTenant = async () => {
    const email =
      bookingDetails?.isSignatory === "no"
        ? bookingDetails?.signatoryUser?.email
        : bookingDetails?.tenantDetails?.email;

    if (email && leaseId) {
      props?.setLoading(true);
      let payload = {
        emails: [email],
        usertype: "tenant",
        leaseId: leaseId,
        inviteAll: false,
      };
      let successMessage = "Invite sent to tenant successfully";
      const status = await sendTenantInvite(payload, successMessage);
      if (status === 200) {
        let body = {
          status: "tenant_invitation_sent",
        };
        await onStatusBookingForm(body);
      }
      props?.setLoading(false);
    } else {
      message.error("No email id present");
    }
  };

  const checkRequiredFields = () => {

    const leaseTerms = bookingDetails?.leaseTerms;
    const tenant = bookingDetails?.tenantDetails;
   
    let requiredFields = [];
    if (
      !leaseTerms?.leaseStartDate ||
      !leaseTerms?.leaseEndDate ||
      !leaseTerms?.leaseNature
    )
      requiredFields.push("Lease Terms");
      
      if(bookingDetails?.propertyDetails?.usagesType != "Commercial"){
        if (!tenant?.tenantNameAR || !tenant?.maritalStatus)
          requiredFields.push("Tenant Details");
      }
      
     
   
    return requiredFields;
  };

  const ApproveTenantInfo = async () => {
    const body = {
      status: "tenant_information_approved",
    };
    const fields: Array<any> = checkRequiredFields();
    if (fields?.length > 0) {
      message.info(`Please kindly complete ${fields?.join(", ")}`);
      return;
    }
    //Task 8297
    if (!bookingDetails?.tenantDetails?.paymentPlanId) { //|| !bookingDetails?.leaseTerms?.contractAmount
      message.info('Contract amount is not present. Please kindly select the payment plan before proceeding');
      return;
    }
    //Task 8442
    if (bookingDetails?.leaseTerms?.moveInDateConfirm != 'yes') {
      message.info('Move-In Date is not confirmed. Please check the lease terms section');
      return;
    }
    PopupConfirm({
      title: `Are you sure you want to approve tenant information?`,
      onOk: async () => {
        await onStatusBookingForm(body);
      },
      okText: "Yes",
    });
  };

  const RejectTenantInfo = async (data:any) => {
    const body = {
      status: "kyc_rejected",
      rejectionReason: data?.comments ?? "",
    };
    const fields: Array<any> = checkRequiredFields();
    if (fields?.length > 0) {
      message.info(`Please kindly complete ${fields?.join(", ")}`);
      return;
    }
    await onStatusBookingForm(body);

    setModalvisible(false);
  };


  // confirm Decline for story 8455
  const confirmDecline = async (data:any) => {
     PopupConfirm({
      title: `This will set the tenant's renewal decision to Renewal Declined and set the Lease status to Active - Vacating. Are you sure you wish to proceed?`,
      onOk:  async() => {
      
        try{         

          const response = await dispatch(
            updateBooking({
              leaseId,
              body: {
                  onlyBooking:true,
                  "renewalToTenant": "renewal_declined",
                  "reason": data?.reason
              }
            }));
            
            if(response?.data?.code === 200){
              message.success(response?.data?.message);

              setModalvisible(false);
             
            }
        }catch(error){
   
          if(error?.response?.data?.success === false){
            message.error(error?.response?.data?.error)
          }
        }
    
      
      },
      okText: "Yes",
    });
   
   
  
   
  };
  
  

  const sendForEsignature = async (document) => {
    PopupConfirm({
      title: `This action will send the document to the tenant for signature. Are you sure?`,
      cancelText: "No",
      okText: "Yes",
      onOk: async () => {
        props?.setLoading(true);
        // const res = await dispatch(sendContractForSign({ leaseId, document }));
        await sendContractForSignService({ leaseId, document });
        await dispatch(getBookingDetail(bookingDetails?.leaseId));
        props?.setLoading(false);
      },
    });
  };

  const sendForApproval = async () => {
    const body = {
      sendForApproval: "yes",
    };
    PopupConfirm({
      title: `This action will send the booking for approval. Are you sure?`,
      onOk: async () => {
        onStatusBookingForm(body);
        await dispatch(getBookingDetail(bookingDetails.leaseId));
      },
      okText: "Yes",
    });
  };

  const editBooking = async (approvalType?: string) => {
    let body = {};
    if (approvalType == "bookingApproval")
      body = { editBooking: "yes", };
    else if (approvalType == "leaseApproval")
      body = { editLeaseApproval: "yes" };

    if (body) {
      onStatusBookingForm(body);
      await dispatch(getBookingDetail(bookingDetails.leaseId));
    }
  };

  const AcceptApproval = async () => {
    const body = { sendForApproval: "approved" };
    PopupConfirm({
      title: `Are you sure you want to approve the booking?`,
      onOk: async () => {
        onStatusBookingForm(body);
        await dispatch(getBookingDetail(bookingDetails.leaseId));
      },
      okText: "Yes",
    });
  };

  const RejectApproval = async (data) => {
    const body = {
      sendForApproval: "rejected",
      rejectionReason: data?.comments ?? "",
    };
    await onStatusBookingForm(body);
    await dispatch(getBookingDetail(bookingDetails.leaseId));
    setModalvisible(false);
  };

  const confirmPayments = async () => {
    if (bookingDetails?.paymentSchedule?.length > 0) {
      let payload = {
        paymentSchedule: bookingDetails?.paymentSchedule,
        leaseId: leaseId,
      };
      if (validateChecks(bookingDetails?.paymentSchedule) == true) return; //TASK: 8273
      PopupConfirm({
        title: `Are you sure you want to confirm the payment schedule?`,
        onOk: async () => {
          props?.setLoading(true);
          const response = await updatePdcService(payload);
          props?.setLoading(false);
          await dispatch(getBookingDetail(bookingDetails?.leaseId));
          message.success("Payment schedule has been confirmed");
        },
        okText: "Yes",
      });
    } else {
      message.error("Payment details not filled");
    }
  };

  const sendLeaseForApproval = async () => {
    const body = { sendLeaseForApproval: "yes" };
    PopupConfirm({
      title: `This action will send the lease for approval. Are you sure?`,
      onOk: async () => {
        onStatusBookingForm(body);
        await dispatch(getBookingDetail(bookingDetails.leaseId));
      },
      okText: "Yes",
    });
  };

  const AcceptLeaseApproval = async () => {
    const body = { sendLeaseForApproval: "approved" };
    PopupConfirm({
      title: `Are you sure you want to approve the lease?`,
      onOk: async () => {
        onStatusBookingForm(body);
        await dispatch(getBookingDetail(bookingDetails.leaseId));
      },
      okText: "Yes",
    });
  };

  const RejectLeaseApproval = async (data) => {
    const body = {
      sendLeaseForApproval: "rejected",
      rejectionReason: data?.comments ?? "",
    };
    await onStatusBookingForm(body);
    await dispatch(getBookingDetail(bookingDetails.leaseId));
    setModalvisible(false);
  };

  const activateEjari = async () => {
    const body = { status: "leaseActive" };
    PopupConfirm({
      title: `Are you sure you want to activate the lease?`,
      onOk: async () => {
        onStatusBookingForm(body);
        await dispatch(getBookingDetail(bookingDetails.leaseId));
      },
      okText: "Yes",
    });
  };

  const createEjari = async () => {
    if (props?.renewal && !bookingDetails?.ejari?.contractNumber) {
      openModalPopup(4);
      return;
    }
    PopupConfirm({
      title: `Are you sure you want to Create Ejari?`,
      onOk: async () => {
        props?.setLoading(true);
        try {
          const res = await CreateEjariContract(leaseId);
          message.success(res?.data?.message);
          props?.setLoading(false);
          await dispatch(getBookingDetail(bookingDetails.leaseId));
        } catch ({ response }) {
          message.error(response?.data?.error);
          props?.setLoading(false);
        }
      },
      okText: "Yes",
    });
  };

  const updateContractNumber = async (data) => {
    let body = { ...bookingDetails?.ejari, ...data };
    await onStatusBookingForm(body);
    await dispatch(getBookingDetail(bookingDetails?.leaseId));
  };

  const handleRenewal = async (type: string, bool?: boolean) => {
    let body: any = {};
    let title: string = "";

    switch (type) {
      case "create":
        body["renewalSendForApproval"] = "yes";
        break;
      case "edit_renewal":
        body["renewalSendForApproval"] = "editRenewal";
        break;
      case "approval":
        body["renewalSendForApproval"] = "approved";
        if (bool)
          title = "This action will approve the renewal request. Are you sure?";
        break;

      // for payment plan approval
      case "payment_plan_approve":
        body["paymentPlanSendForApproval"] = "approved";
        if (bool) title = "Are you sure you want to approve payment plans?";
        break;
    }

    if (title)
      PopupConfirm({
        title: title,
        onOk: async () => {
          onStatusBookingForm(body);
        },
        okText: "Yes",
      });
    else {
      onStatusBookingForm(body);
    }
  };

  const openModalPopup = (index) => {
    setModalTrigger(index);
    setModalvisible(true);
  };

  const onRejectPaymentApproval = async (data: any) => {
    await onStatusBookingForm({
      comments: data?.comments,
      paymentPlanSendForApproval: "rejected",
    });
    setModalvisible(false);
  };

  const onRejectApproval = async (data: any) => {
    await onStatusBookingForm({
      comments: data?.comments,
      renewalSendForApproval: "rejected",
    });
    setModalvisible(false);
  };

  //  ******* for stroy 8455 Upcoming Renewals > Add buttons for Confirm Renewal and Confirm Move-out ****
  const confirmRenwal = (renewalStatus:string)=>{
   
    const inputDate = new Date(bookingDetails?.leaseTerms?.leaseStartDate);
    const currentDate = new Date(Date.now());
    const isExpired = currentDate > inputDate;
     // according to Aajay for 8455 if lease Start past or less than current date then lease is expired
     if((leaseStatus === 'renewal_sent'  && renewalStatus === 'sent') || renewalStatus === 'renewal_declined' || renewalStatus ==='sent' || renewalStatus === 'ready_to_send') return true;
     else if(leaseStatus === 'renewal_sent'  && renewalStatus === 'sent' && isExpired) return true ;
   
  }

  //  ******* for stroy 8455 Upcoming Renewals > Add buttons for Confirm Renewal and Confirm Move-out ****
  const declinRenwal = (renewalStatus:string)=>{
    if(
      (leaseStatus === 'renewal_sent'  && renewalStatus === 'sent') ||
     ( leaseStatus != 'active'  && renewalStatus === 'renewal_accepted') ||
       renewalStatus ==='sent' ||
       renewalStatus === 'ready_to_send'
        )

          return true;
    }

//  ******* for stroy 8455 Upcoming Renewals > Add buttons for Confirm Renewal and Confirm Move-out ****
const confirmMoveOut = (renewalStatus:string)=>{
   if(renewalStatus === 'renewal_declined') return true;
}

  const showModalForConfirmRenwal = ()=>{
    props.handleEditPressEvent('4',true);
  }

  const reasonsData = [
    { value: "Rent is to highh", label: "Rent is to high" },
    { value: "Relocating to another country", label: "Relocating to another country" },
    { value: "Relocating to another city", label: "Relocating to another city" },
    { value: "Issues with maintenance", label: "Issues with maintenance" },
    { value: "Moving within the building", label: "Moving within the building" },
    { value: "Moving to own property", label: "Moving to own property" },
    { value: "Downsizing to smaller property", label: "Downsizing to smaller property" },
    { value: "Upgrading to larger property", label: "Upgrading to larger property" }
  ];

  const popups = [
    {
      content: (
        <RejectPopup
          handleReject={(data) => RejectTenantInfo(data)}
          onClose={() => setModalvisible(false)}
        />
      ),
    },
    {
      content: (
        <RejectPopup
          handleReject={RejectApproval}
          onClose={() => setModalvisible(false)}
        />
      ),
    },
    {
      content: (
        <RejectPopup
          handleReject={RejectLeaseApproval}
          onClose={() => setModalvisible(false)}
        />
      ),
    },
    {
      content: (
        <RejectPopup
          handleReject={onRejectApproval}
          onClose={() => setModalvisible(false)}
        />
      ),
    },
    {
      title: (
        <Title level={3} className="mb-0">
          Add Ejari Contract Number
        </Title>
      ),
      content: <AddContractNumber handleUpdate={updateContractNumber} />,
    },
    {
      content: (
        <RejectPopup
          handleReject={onRejectPaymentApproval}
          onClose={() => setModalvisible(false)}
        />
      ),
    },
    {
      title: (
        <Title level={3} className="mb-0">
          Cancel Booking 
        </Title>
      ),
      content: <RejectPopup
        handleReject={onCancelBook}
        type={"cancel"}
        onClose={() => setModalvisible(false)}
      />,
    },
    {
      title: (
        <Title level={3} className="mb-0">
          Decline Renewal
        </Title>
      ),
      content: (
        <RejectPopup
          handleReject={(data) => confirmDecline(data)}
          onClose={() => setModalvisible(false)}
          reasonsData={reasonsData}
          label={"Select a vacating reason"}
          type={'decline_renewal'}
        />
      ),
    },
    {
title:(
<Title  level={3} className="mb-0">
Confirm Move-out
</Title>
      ),
      content: (
        <MoveOutPopup
          onClose={() => setModalvisible(false)}
          propertyId={bookingDetails?.propertyId}
          leaseExpireDate = {bookingDetails?.parentLeaseTerms?.leaseEndDate}
          propertyDetail={bookingDetails?.propertyDetails}
          unitId={bookingDetails?.unitId}
        />
      ),
    },       
  ];

  const popupProps = {
    closable: true,
    visibility: modalvisible,
    title: popups[modalTrigger]?.title || (
      <Title level={3} className="mb-0">
        Reject Reason
      </Title>
    ),
    content: popups[modalTrigger]?.content,
    width: 650,
    onCancel: () => setModalvisible(false),
  };

  const leaseStatus = bookingDetails?.leaseStatus;
  const renewalStatus = bookingDetails?.renewalStatus;

  const renewalApproval =
    bookingDetails?.renewalApproval === "enable" ? true : false;
  const managerApproval =
    bookingDetails?.managerApproval === "enable" ? true : false;
  const leaseManagerApproval =
    bookingDetails?.leaseManagerApproval === "enable" ? true : false;

  const documentsPending = bookingDetails?.requiredDocuments?.filter(
    (item) => item.status !== "approved"
  )?.length;

  const kycApproved = !!bookingDetails?.leaseStatusHistory?.find(
    (e) => e.status === "kyc_approved"
  );
  const bookingApproved = !!bookingDetails?.leaseStatusHistory?.find(
    (e) => e.status === "booking_approved"
  );
  const depositPaid = !!bookingDetails?.leaseStatusHistory?.find(
    (e) => e.status === "deposit_paid"
  );
  const paymentsReceived = !!bookingDetails?.leaseStatusHistory?.find(
    (e) => e.status === "rent_payments_received"
  );
  const contractCreated = !!bookingDetails?.leaseStatusHistory?.find(
    (e) => e.status === "contract_created"
  );
  const contractSent = !!bookingDetails?.leaseStatusHistory?.find(
    (e) => e.status === "awaiting_tenant_signature"
  );
  const tenantSigned = !!bookingDetails?.leaseStatusHistory?.find(
    (e) => e.status === "awaiting_landlord_signature"
  );
  const contractSigned = !!bookingDetails?.leaseStatusHistory?.find(
    (e) => e.status === "contract_signed"
  );
  const bookingCancelled = !!bookingDetails?.leaseStatusHistory?.find(
    (e) => e.status === "booking_cancelled"
  );

  const statusMappingArray = [
    { key: "booking_created", value: leaseStatus == "booking_created" },
    {
      key: "tenant_invitation_sent",
      value: leaseStatus == "tenant_invitation_sent",
    },
    {
      key: "tenant_submitted",
      value:
        leaseStatus == "kyc_submitted" || leaseStatus == "documents_submitted",
    },
    {
      key: "documents_approved",
      value:
        leaseStatus == "documents_approved" ||
        (bookingDetails?.requiredDocuments?.length > 0 &&
          documentsPending == 0),
    },
    {
      key: "kyc_approved",
      value: leaseStatus == "kyc_approved" || kycApproved,
    },
    { key: "kyc_rejected", value: leaseStatus == "kyc_rejected" },
    {
      key: "send_for_booking_approval",
      value:
        kycApproved &&
        managerApproval &&
        !bookingApproved &&
        leaseStatus != "booking_form_sent" &&
        !paymentsReceived,
    }, //last2 part added to hide on the status
    {
      key: "sent_for_booking_approval",
      value: leaseStatus == "sent_for_booking_approval",
    },
    { key: "confirm_pdc", value: leaseStatus == "sent_for_booking_approval" },
    {
      key: "send_for_lease_approval",
      value: paymentsReceived && leaseManagerApproval && !contractCreated,
    },
    {
      key: "sent_for_lease_approval",
      value: leaseStatus == "sent_for_lease_approval",
    },
    {
      key: "booking_cancelled",
      value: bookingCancelled || leaseStatus == "booking_rejected",
    },
    { key: "active", value: leaseStatus == "active" },
  ];

  const statusMap = statusMappingArray.reduce((acc, { key, value }) => {
    acc[key] = value;
    return acc;
  }, {});

  // show cancel booking button until booking not deposit for this purpose
  // first I am checking leaseHistory if deposit exist in leaseHistory then dnot show cancel booking button
  const getDepositStatus = bookingDetails?.leaseStatusHistory?.find((item)=>item?.status === 'deposit_paid')

  const actionButtons: Array<actionType> = [
    {
      label: "Cancel Booking",
      type: "delete",
      action: () => openModalPopup(6),
      visible:
        !props.renewal &&
        !bookingApproved &&
        !statusMap["active"] &&
        !bookingCancelled &&
        !getDepositStatus?.status

    },
    {
      label: "Send to tenant",
      type: "accept",
      action: () => sendToTenant(),
      visible: !props.renewal && statusMap["booking_created"],
    },
    {
      label: "Re-Send to tenant",
      type: "accept",
      action: () => sendToTenant(),
      visible: !props.renewal && statusMap["tenant_invitation_sent"],
    },
    {
      label: "Approve Tenant Information",
      type: "accept",
      action: () => ApproveTenantInfo(),
      visible:
        !bookingCancelled &&
        !props.renewal &&
        statusMap["documents_approved"] &&
        !statusMap["kyc_approved"] &&
        !statusMap["kyc_rejected"] &&
        bookingDetails?.paymentPlanApproval != "sent",
    },
    {
      label: "Reject Tenant Information",
      type: "reject",
      action: () => openModalPopup(0),
      visible:
        !bookingCancelled &&
        !props.renewal &&
        statusMap["documents_approved"] &&
        !statusMap["kyc_approved"] &&
        !statusMap["kyc_rejected"] &&
        bookingDetails?.paymentPlanApproval != "sent",
    },
    {
      label: `${statusMap["sent_for_booking_approval"] ? "Sent" : "Send"
        } for Approval`,
      type: "accept",
      action: () => sendForApproval(),
      visible:
        !props.renewal &&
        !bookingCancelled &&
        (statusMap["send_for_booking_approval"] ||
          statusMap["sent_for_booking_approval"]) &&
        loginUserType(["leaseManager", "landlord"]),
      disabled: statusMap["sent_for_booking_approval"] || bookingDetails?.paymentPlanApproval === "sent",
    },
    {
      label: "Edit Booking",
      type: "accept",
      action: () => editBooking("bookingApproval"),
      visible:
        !props.renewal &&
        !bookingCancelled &&
        loginUserType(["leaseManager", "landlord"]) &&
        statusMap["sent_for_booking_approval"],
    },
    {
      label: "Approve",
      type: "accept",
      action: () => AcceptApproval(),
      visible:
        !props.renewal &&
        loginUserType(["managersManager", "landlord"]) &&
        managerApproval &&
        statusMap["sent_for_booking_approval"],
    },
    {
      label: "Reject",
      type: "reject",
      action: () => openModalPopup(1),
      visible:
        !props.renewal &&
        loginUserType(["managersManager", "landlord"]) &&
        managerApproval &&
        statusMap["sent_for_booking_approval"],
    },

    // *****  for story 8455 Upcoming Renewals > Add buttons for Confirm Renewal and Confirm Move-out ****** 

{
  label: "Decline Renewal",
  type: "delete",
  action: () => openModalPopup(7), //this will come in renewal
  visible:  declinRenwal(renewalStatus), //(bookingApproved ||  removed as per this bug: 7891
},
{
  label: "Confirm Move-out",
  type: "delete",
  action: () => openModalPopup(8), //this will come in renewal
  visible:  confirmMoveOut(renewalStatus), //(bookingApproved ||  removed as per this bug: 7891
},
    {
      label: "Confirm Renewal",
      type: "accept",
      action: () => showModalForConfirmRenwal(), //this will come in renewal
      visible:  confirmRenwal(renewalStatus), //(bookingApproved ||  removed as per this bug: 7891
    },
    {
      label: "Confirm Payment Schedule",
      type: "accept",
      action: () => confirmPayments(), //this will come in renewal
      visible: !bookingCancelled && depositPaid && !paymentsReceived, //(bookingApproved ||  removed as per this bug: 7891
    },
    {
      label: `${statusMap["sent_for_lease_approval"] ? "Sent" : "Send"
        } Lease for Approval`,
      type: "accept",
      action: () => sendLeaseForApproval(),
      //!props.renewal &&             //this will come in renewal
      visible:
        !bookingCancelled &&
        (statusMap["send_for_lease_approval"] ||
          statusMap["sent_for_lease_approval"]) &&
        (!props.renewal ? loginUserType(["leaseManager", "landlord", "adminManager"]) : true) && //added renewal free check as per Vishwajeet
        !statusMap["active"],
      disabled: statusMap["sent_for_lease_approval"],
    },
    {
      label: `Edit ${!props.renewal ? "Booking" : "Renewal"}`,
      type: "accept",
      action: () => editBooking("leaseApproval"),
      visible:
        !bookingCancelled &&
        (!props.renewal ? loginUserType(["leaseManager", "landlord", "adminManager"]) : true) &&
        statusMap["sent_for_lease_approval"],
    },
    {
      label: "Approve Lease",
      type: "accept",
      action: () => AcceptLeaseApproval(),
      //!props.renewal &&                    //this will come in renewal
      visible:
        loginUserType(["managersManager", "landlord", "adminManagersManager"]) &&
        leaseManagerApproval &&
        statusMap["sent_for_lease_approval"],
    },
    {
      label: "Reject Lease",
      type: "reject",
      action: () => openModalPopup(2),
      //!props.renewal &&                 //this will come in renewal
      visible:
        loginUserType(["managersManager", "landlord", "adminManagersManager"]) &&
        leaseManagerApproval &&
        statusMap["sent_for_lease_approval"],
    },
    {
      label: `${contractSent ? "Re-send" : "Send"} Contract for eSignature`,
      type: "accept",
      action: () => sendForEsignature("TENANCY_CONTRACT"),
      //!props.renewal &&  //this will come in renewal
      visible:
        !bookingCancelled &&
        contractCreated &&
        !tenantSigned &&
        !contractSigned && //KT test scenario
        loginUserType(["leaseManager", "landlord", "adminManager", "adminManagersManager"]), //added adminManager checks ref: 8289 //added adminManagersManager: ref 8400
    },
    {
      label: "Activate",
      type: "accept",
      action: () => activateEjari(),
      //!props.renewal && //this will come in renewal
      visible: !bookingCancelled && contractSigned && !statusMap["active"],
    },
    {
      label: "Create Ejari Contract",
      type: "accept",
      action: () => createEjari(),
      visible:
        !state?.renewal &&
        statusMap["active"] &&
        bookingDetails?.leaseStatusHistory?.length > 1, //renewal check added for bug: 8287
    },
    {
      label: "Download Ejari Certificate",
      type: "accept",
    },
    {
      label: "Sent Plan for Approval",
      type: "accept",
      action: () => { },
      visible:
        !props.renewal &&
        loginUserType(["leaseManager"]) &&
        bookingDetails?.paymentPlanApproval === "sent",
      disabled: true,
    },
    {
      label: "Approve",
      type: "accept",
      action: () => handleRenewal("payment_plan_approve", true),
      visible:
        !props.renewal &&
        loginUserType(["managersManager", "landlord"]) &&
        bookingDetails?.paymentPlanApproval === "sent",
    },
    {
      label: "Reject",
      type: "reject",
      action: () => openModalPopup(5),
      visible:
        !props.renewal &&
        loginUserType(["managersManager", "landlord"]) &&
        bookingDetails?.paymentPlanApproval === "sent",
    },
    {
      type: "accept",
      visible: renewalStatus === "to_review",
      action: () => handleRenewal(renewalApproval ? "create" : "approval"),
      label: `${renewalApproval ? "Send for Approval" : "Ready to Send"}`,
    },
    {
      type: "accept",
      label: "Approve",
      action: () => handleRenewal("approval", true),
      visible:
        renewalStatus === "sent_for_approval" &&
        loginUserType(["landlord", "managersManager"]),
    },
    {
      type: "reject",
      label: "Reject",
      action: () => openModalPopup(3),
      visible:
        renewalStatus === "sent_for_approval" &&
        loginUserType(["landlord", "managersManager"]),
    },
    {
      type: "accept",
      label: "Edit Renewal",
      action: () => handleRenewal("edit_renewal"),
      visible: renewalStatus === "sent_for_approval",
    },
  ];

  return (

    <>
      <Space>
        {actionButtons
          .filter((e) => e.visible)
          .map((item, index) => {
            let button = { classes: "", buttonType: "" };
            if (item.type == "delete") button.classes = "ef-discard-btn";
            else if (item.type == "reject") button.classes = "ef-reject-btn";
            else if (item.type == "accept") button.classes = "ef-approve-btn";
            return (
              <Button
                key={index}
                className={button?.classes}
                disabled={item?.disabled}
                type="primary"
                onClick={item.action}
              >
                {item.label}
              </Button>
            );
          })}
      </Space>
      <Popup destroyOnClose={true} {...popupProps} />
    </>
  );
};

export default index;
