import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import DashboardLayout from "../../molecules/DashboardLayout";
import { useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Pagination,
  Card,
  Button,
  message,
  Tag,
} from "antd";
  
import SectionHeader from "../../molecules/SectionHeader";
import Grid from "../../atoms/Grid";
import { getLeaseForms, visiblePopup } from "./ducks/actions";
import { Popup } from "../../atoms/Popup";
import CancelLeaseForm from "./cancelLease";
import { dateByformat } from "../../../utils/dateHelper";
import { currencyFormat, statusLabel } from "../../../utils/media";
import { useSearchHistory } from "../../../utils/filterHelper";
import { setFilterLease } from "../App/SearchFilters/ducks/actions";
import LoaderTsx from "../Loader.tsx";

const gridProps = {
  sortable: true,
  filter: true,
  resizable: true,
  minWidth: 220,
};

const searchKeys = [
  { value: "name", label: "Name" },
  { value: "unitCode", label: "Unit Number", default: true },
  { value: "property", label: "Property" },
  { value: "leaseExecutive", label: "Lease Executive" },
];

const getModifiedStatus = (param) => {
  const renewalleaseStatus = param?.data?.renewalleaseStatus;
  const renewalStatus = param?.data?.renewalStatus;
  const leaseStatus = param?.data?.leaseStatus;
  const isRenewing = param?.data?.isRenewing;




  if (renewalStatus || renewalleaseStatus) {

    //   after discussion with @vishwajeet if leaseStatus === active and renewalStatus === renewal_decline then show
  //  status active_vacating 
    
   if(leaseStatus === "active" && renewalStatus === "renewal_declined")   
    return (
      <Tag className="tag-sm" color="success">
        { statusLabel('active_vacating')}
      </Tag>
    );
   if(leaseStatus === "active" && renewalStatus === "renewal_accepted")
    
   return (
    <Tag className="tag-sm" color="success">
      {statusLabel('active_renewing')}
    </Tag>
     );
  
    if (renewalleaseStatus === "booking_cancelled") {
      return (
        <Tag className="tag-sm" color="warning">
          {statusLabel(renewalleaseStatus)}
        </Tag>
      );
    }
    // according to story number 8688
    if(isRenewing && leaseStatus == 'lease_expired' &&  renewalStatus == 'renewal_accepted'){
  
      return (
        <Tag className="tag-sm" color="success">
          {statusLabel(leaseStatus)}
        </Tag>
      );
    
    }
  
    // according to story number 8688
    if(isRenewing && leaseStatus == 'lease_expired' && renewalStatus == 'renewal_declined'){
      return (
        <Tag className="tag-sm" color="success">
          {statusLabel(leaseStatus)}
        </Tag>
      );
    } 
     
  
    if (
      renewalleaseStatus == "active_vacating" ||
      renewalleaseStatus == "booking_reject" 
    ) {
      return (
        <Tag className="tag-sm" color="warning">
          {/* icon={<ExclamationCircleOutlined />} */}
          {statusLabel("active_vacating")}
        </Tag>
      );
    } else if (renewalleaseStatus == "booking_cancelled") {
      //booking_cancelled scenario is new
      return (
        <Tag className="tag-sm" color="success">
          {/* icon={<ExclamationCircleOutlined />} */}
          {statusLabel(leaseStatus)}
        </Tag>
      );
    } else if (renewalleaseStatus == "renewal_sent") {
      //booking_cancelled scenario is new
      return (
        <Tag className="tag-sm" color="success">
          {statusLabel(leaseStatus)}
        </Tag>
      );
    }else {
      <Tag className="tag-sm" color="success">
      {statusLabel(leaseStatus)}
    </Tag>
    }

  } else {
    return (
      <Tag className="tag-sm" color="success">
        {statusLabel(leaseStatus)}
      </Tag>
    );
  }


};

const leadCol = [
  {
    headerName: "Status",
    field: "leaseStatus",
    minWidth: 250,
    cellRendererFramework: getModifiedStatus,
  },
  {
    headerName: "Property",
    field: "propertyDetails.propertyName",
    // minWidth: 320,
  },
  {
    headerName: "Unit Number",
    field: "propertyDetails.unitCode",
    minWidth: 320,
  },
  {
    headerName: "Tenant",
    field: "tenantDetails.tenantNameEN",
  },
  {
    headerName: "Annual Rent",
    field: "leaseTerms.contractAmount",
    cellRendererFramework: ({ data }) => {
      return (
        <div className="annualRent">
          {currencyFormat(data.leaseTerms.contractAmount)}
        </div>
      );
    },
  },
  {
    headerName: "Start Date",
    field: "leaseTerms.leaseStartDate",
    minWidth: 150,
    cellRendererFramework: ({ data }) => {
      return dateByformat(data.leaseTerms.leaseStartDate, "DD-MM-YYYY");
    },
  },
  {
    headerName: "End Date",
    field: "leaseTerms.leaseEndDate",
    minWidth: 150,
    cellRendererFramework: ({ data }) => {
      return dateByformat(data.leaseTerms.leaseEndDate, "DD-MM-YYYY");
    },
  },
  {
    headerName: "Contract Type",
    field: "leaseType",
    cellRendererFramework: ({ data }) => {
      return statusLabel(data.leaseType);
    },
  },
  {
    headerName: "AWB No",
    field: "shipmentNumber",
  },
  // {
  //   headerName: "Renewal Status",
  //   field: "renewalleaseStatus",
  //   minWidth: 180,
  //   cellRendererFramework: ({ data }) => data?.renewalleaseStatus ? data?.renewalleaseStatus :
  //     data?.isRenewing === true ? (<span style={{ color: "green" }}>Active Renewing</span>) : "—",
  // },
  // {
  //   // headerName: "Renewal Status",
  //   // field: "renewalleaseStatus",
  //   minWidth: 170,
  //   cellRendererFramework: ({ data }) => data?.isRenewing === true ? (<span style={{ color: "green" }}>Active Renewing</span>) : "—",
  // },
  // {
  //   headerName: "Created At",
  //   field: "createdAt",
  //   cellRendererFramework: dateFormat,
  // },
  {
    headerName: "Actions",
    field: "manage",
    cellRendererFramework: (row) => viewButton(row),
    pinned:'right',
    maxWidth:140
  },
];

const limit = 50;

const viewButton = (row) => {
  const navigate = useNavigate();
  const viewForm = () => {
    navigate(`/viewbookingform/${row.data.leaseId}`);
    
  };

  return (
    <Button size="large" type="primary" onClick={viewForm}>
      View Lease
    </Button>
  );
};

const bookingStatuses = [
  {
    "label": "All",
    "value": "all"
  },
  {
    "label": "Active",
    "value": "active"
  },
  {
    "label": "Active Renewing",
    "value": "active_renewing"
  },
  {
    "label": "Active Vacating",
    "value": "active_vacating"
  },
  {
    "label": "Expired Leases",
    "value": "lease_expired"
  },
];

const LeaseformList = (props) => {
  const dispatch: any = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [bookingStatus, setBookingStatusFilter] = useState(null);
  const[isLoading,setIsLoading] = useState(false);
  const filter = useSelector((state: any) => state.filters.leasesData);
  const [searchBy, setSearchBy] = useState(Object.keys(filter)[0] ?? "unitCode");
  const [search, setSearch] = useState(filter[Object.keys(filter)[0]] ?? "");
  const { searchHistory, addToSearchHistory } = useSearchHistory(setFilterLease);
  const leaseformdata = useSelector((state: any) => state.leases.getLeaseForms);

  const onClosePopUp = () => {
    dispatch(visiblePopup());
  };

  const popupProps = {
    title: "Enter Unit Code",
    width: 650,
    content: <CancelLeaseForm closePopUp={onClosePopUp} />,
    onCancel: onClosePopUp,
  };

  const handlePagination = (pg) => {
    setCurrentPage(pg);
    setIsLoading(true);
    dispatch(getLeaseForms(limit, pg, search, searchBy,setIsLoading,bookingStatus?.value));
  };

  function onTopSearch(e) {
    if (e?.length === 0) setSearch("");
    else if (e?.length < 2)
      message.error("Please enter atleast two characters");
    else setSearch(e);
  }

  function onTopSearchBy(e) {
    setSearchBy(e?.value);
  }

  useEffect(() => {
    setCurrentPage(1);
    addToSearchHistory(searchBy, search); 
    setIsLoading(true);
    dispatch(getLeaseForms(limit, 1, search, searchBy,setIsLoading,bookingStatus?.value));
   
  }, [search, searchBy,bookingStatus?.value]);

  useEffect(() => {
    let key = Object.keys(searchHistory)[0];
    if (searchKeys.length > 0)
      searchKeys.map((item) => {
        if (key == item.value) item["default"] = true;
        else item["default"] = false;
        return item;
      });
   
  }, [searchHistory]);

  return (
    <Fragment>
      <DashboardLayout>
        <Row gutter={[32, 32]}>
          <Col span={24}>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <SectionHeader
                  heading="Leases"
                  bookingStatusFilter={setBookingStatusFilter}
                  //@ts-ignore
                  cancelledBookingCheck = {false}
                  bookingStatus={true}
                  bookingStatuses={bookingStatuses}
                  bookingStatusTitle={'Filter By Lease Status'}
                  total={leaseformdata?.totalCount || 0}
                  search={(e) => onTopSearch(e)}
                  searchBy={(e) => onTopSearchBy(e)}
                  defaultSearch={search}
                  searchOptions={searchKeys}
                />
              </Col>
              <Col span={24}>
                <Pagination
                  className="ag-property-text-pagination"
                  total={leaseformdata?.totalCount || 0}
                  showTotal={(total, range) =>
                    `Showing ${range[0]}-${range[1]} of ${total} Leases`
                  }
                  showSizeChanger={false}
                  defaultPageSize={50}
                  defaultCurrent={1}
                  current={currentPage}
                  onChange={(e) => handlePagination(e)}
                />
              </Col>
              <Col span={24}>
                <Row gutter={[24, 24]} justify="end">
                  <Col span={24}>
                    <Card
                      bordered={false}
                      className="ag-nospace-body"
                    >
                     { 
                     isLoading ?

                      <LoaderTsx/>
                     :
                      
                      <Grid
                        refCol="status"
                        //@ts-ignore
                        data={leaseformdata?.data ?? []}
                        columns={leadCol}
                        pagination={false}
                        defaultSettings={gridProps}
                        noRowlabel="Lease"
                        fullPage={true}
                      />}
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </DashboardLayout>
      <Popup {...popupProps} />
    </Fragment>
  );
};

export default LeaseformList;
