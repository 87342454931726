export const notificationsDirector = {
  lead_item_id: "",
  lease_id: "",
};

export const SITEKEY = "6LfyGo8bAAAAAPNwvOlUiLfMelq9dII867qVxKvs";
export const SECRETKEY = "6LfyGo8bAAAAAAj78sbeF8l4ShR-WPmN0Lkt4-ND";
// export const EQARI_GOOGLE_KEY = "AIzaSyB1eh7ZlrBG46F_bkJUQcn-4WDjMEs3Unc";
export const EQARI_GOOGLE_KEY = "AIzaSyAtdkaVektzwf-CUDmq2J6lGFMDfO7uxio"; // key same as mobile app
export const EQARI_GOOGLE_KEY_TRANSLATION ="AIzaSyB2SrxenJdlrPFPg_IHF_eZ7_thC0DP4Qg"; // key same as mobile app

// DEV
// development Base Url using for EQARCOM+ NEW APIs
// export const BASE_URL = "https://eqarcom-gateway-api.agp-dev.com/api/v1";
// export const baseUrl = "https://eqarcom-gateway-api.agp-dev.com";
// export const serverUrl = "https://d2mykrr58m5d3e.cloudfront.net";
export const TRANSLATION_URL =
  "https://translation.googleapis.com/language/translate/v2";
// //UATP
// // staging Base Url using for EQARCOM+ NEW APIs
// export const BASE_URL = "https://eqarcom-gateway-uat.agp-dev.com/api/v1";
// export const baseUrl = "https://eqarcom-gateway-uat.agp-dev.com";
// export const serverUrl = "https://d2mykrr58m5d3e.cloudfront.net";
// PROD
// Production Base Url using for EQARCOM+ NEW APIs
export const BASE_URL =  "https://connect.eqarcom.ae/api/v1";
export const baseUrl =   "https://eqarcom-gateway-uat.agp-dev.com";
export const serverUrl = "https://app.eqarcom.ae";
// HOTFIX
// Production Base Url using for EQARCOM+ NEW APIs
// export const BASE_URL = "https://eqarcom-gateway-hotfix.agp-dev.com/api/v1";
// export const baseUrl = "https://eqarcom-gateway-hotfix.agp-dev.com";
// export const serverUrl = "https://dw9ddt4u56hxs.cloudfront.net";

//ENDPOINTS FOR APIs
export const propertyService = baseUrl + "/api/v1";
export const leaseService = baseUrl + "/api/v1";
export const unitService = baseUrl + "/api/v1";
export const authService = baseUrl + "/api/v1";
export const contactUs = baseUrl + "/api/v1";
export const managementService = baseUrl + "/api/v1";
export const maintenanceService = baseUrl + "/api/v1";
export const mediaService = baseUrl + "/api/v1";
export const contractService = baseUrl + "/api/v1";
export const maintenanceTicketsService =
  baseUrl + "/api/v1/property-landlord/asset/tickets";
export const singleMaintenanceTicketsService =
  baseUrl + "/api/v1/property-landlord/asset/ticket";
export const scheduleVisitService = baseUrl + "/api/v1/property-landlord";
export const baseContractService = baseUrl + "/lead-to-lease/api/v1/contract";
export const bookingFormUnit = baseUrl + "/api/v1/property-landlord";
export const dashboard = baseUrl + "/api/v1/power-bi/report";
export const getUserService = baseUrl+"/api/v1" +"/masterUser";
// Production
// export const BASE_URL = "https://prod-api.agp-dev.com/auth/api/v1";
// export const baseUrl = "https://prod-api.agp-dev.com";
// export const propertyService = baseUrl + "/api/v1";
// export const leaseService = baseUrl + "/lead-to-lease/api/v1";
// export const authService = baseUrl + "/auth/api/v1";
// export const maintenanceService = baseUrl + "/maintenance/api/v1";
// export const maintenanceTicketsService =
//   baseUrl + "/api/v1/property-landlord/asset/tickets";
// export const singleMaintenanceTicketsService =
//   baseUrl + "/api/v1/property-landlord/asset/ticket";
// export const scheduleVisitService = baseUrl + "/api/v1/property-landlord";
// export const baseContractService = baseUrl + "/lead-to-lease/api/v1/contract";
// export const bookingFormUnit = baseUrl + "/api/v1/property-landlord";

// export const baseUrl = process.env.API_URL;
// console.log("basspassand",baseUrl);
// export const extra = process.env.EXTRA;
// export const addition = process.env.ADDITON;

// export const propertyService = baseUrl + extra + "api/v1";
// export const leaseService = baseUrl + addition + "lead-to-lease/api/v1";
// export const authService = baseUrl + addition + "auth/api/v1";
// export const maintenanceService = baseUrl + addition + "maintenance/api/v1";
// export const maintenanceTicketsService =
//   baseUrl + extra + "api/v1/property-landlord/asset/tickets";
// export const singleMaintenanceTicketsService =
//   baseUrl + extra + "api/v1/property-landlord/asset/ticket";
// export const scheduleVisitService =
//   baseUrl + extra + "api/v1/property-landlord";
// export const baseContractService =
//   baseUrl + addition + "lead-to-lease/api/v1/contract";
// export const bookingFormUnit = baseUrl + extra + "api/v1/property-landlord";
