import React, { useState, useEffect, useRef } from "react";
import DashboardLayout from "../../../molecules/DashboardLayout";
import { Row, Col, Pagination,Button,Calendar, Card } from "antd";
import SectionHeader from "../../../molecules/SectionHeader";
import Grid from "../../../atoms/Grid";
import { useDispatch, useSelector } from "react-redux";
import { getPDC, updatePDCStatus, getOnePDC, getBUList } from "../ducks/actions";
import {
  EditOutlined,
  PaperClipOutlined,
  InfoCircleOutlined
} from "@ant-design/icons";
import axios from "../../../../utils/axiosInceptor";
import {
  leaseService,
} from "../../../../configs/constants";
import Loader from "../../Loader.tsx";
import { emptyPropertyUnits, getPropertyUnits } from "../../Properties/PropertyDetail/ducks/actions";
import { getPropertiesDrop, getPropertiesDropBUID } from "../../Properties/ducks/actions";
import { paymentStatus, paymentModes, paymentTypes } from "../../Properties/ducks/constants";
import { dateByformat } from "../../../../utils/dateHelper";
import { currencyFormat } from "../../../../utils/media";
import Title from "antd/es/typography/Title";
import ViewPayment from "./ViewPayAttachment/ViewPayment";
import CreditCardRequest from "./CreditCardRequest/CreditCardRequest";
import ModifyPaymentStatus from "./ModifyPaymentStatus";
import { useLocation, useNavigate } from 'react-router-dom'
import { Popup } from "../../../atoms/Popup";

var _ = require("lodash");
const gridProps = {
  sortable: true,
  filter: true,
  resizable: true,
  minWidth: 200,
};

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default (props) => {
  const dispatch: any = useDispatch();
  let query = useQuery();
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [paymentStatusInfo, setPaymentStatusInfo] = useState(null);

  const [pdcList, setPdcList] = useState([]);

  const units = useSelector((state: any) => state.propertyDetails.unitsDropdown);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [status, setStatus] = useState("pending");
  // for story 8503 
  const [selectPaymentMode, setPaymentMode] = useState(null);
  const [selectPaymentType, setPaymentType] = useState(null);

  const[exportData,setExportData] = useState(false);

  const [buId, setBUId] = useState(null);
  const [isRequestLoading, setIsRequestLoading] = useState(false);
  const [showAll, setShowAll] = useState(true);
  const [updateValue, setUpdateValue] = useState({});
  const [visible, setVisible] = useState({ status: false, index: 1 });
  const [selectedPDCs, setSelectedPDC] = useState(null);
  const [selectedPendingPDCs, setSelectedPendingPDC] = useState([]);
  const[isLoading,setIsLoading] = useState(false);

  const [buOptions, setBUOptions] = useState([
    {
      name: "businessUnit",
      label: "Landlord / Business Unit",
      placeholder: "All Landlords",
      defaultValue: 'All Landlords',
      options: [],
    },
  ]);



  useEffect(() => {
     (async () => {
      const res = await getBUList()

      let unitOptions = res?.length > 0
        ? _.map(res, (item, index) => ({
          value: item.businessUnitName,
          label: item.businessUnitName,
          a: item.businessUnitId
        }))
        : [];

      unitOptions.unshift({
        value: 'All Landlords',
        label: 'All Landlords',
        a: 'All Landlords'
      })

      setBUOptions([
        {
          name: "businessUnit",
          label: `Landlord / Business Unit (${unitOptions?.length - 1})`,
          placeholder: "All Landlords",
          defaultValue: 'All Landlords',
          options: unitOptions,
        },
      ]);
    })()
  }, []);

  const gridRef = useRef(null);



  const PaymentModeOptions = [
    'credit_card',
    'cheque',
    'bank_transfer',
    'cash',
    'directDebit'
  ]

  const pdcCol = [
    {
      headerName: "",
      cellStyle: { textAlign: "center", padding: "0" },
      maxWidth: 30,
      cellRendererFramework: ({ data }) => {
        return (<>
          <Button
            icon={<PaperClipOutlined />}
            onClick={() => { viewPaymentAttachment(data) }}
            type="link"
            className={"ag-primary " + (data?.documentId ? "button-active" : "button-inactive")}
          />
        </>);
      },
      pinned: 'left'
    },
    
    {
      headerName: "Property",
      field: "propertyName",
      cellRendererFramework: ({ data }) => data?.propertyDetails?.propertyName	
    },
    {
      headerName: "Tenant Name",
      field: "tenantName",
      cellRendererFramework: ({ data }) => data?.tenantDetails?.tenantNameEN	
      
    },
    
    {
      headerName: "Unit Number",
      field: "unitNumber",
      width: '240',
      cellRendererFramework: ({ data }) => data?.propertyDetails?.unitNumber
    },
    {
      headerName: "Lease Term",
      field: "leaseTerms",
      width: '240',
      cellRendererFramework: ({ data }) => `(${dateByformat(data?.leaseTerms?.leaseStartDate, "DD-MM-YYYY")} - ${dateByformat(data?.leaseTerms?.leaseEndDate, "DD-MM-YYYY")})`
    },
    {
      headerName: "Unit Code",
      field: "unitCode",
      width: '300'
    },
    {
      headerName: "Due Date",
      field: "dueDate",
      cellRendererFramework: ({ data }) => {
        return dateByformat(data.dueDate, "DD-MM-YYYY");
      },
    },
    {
      headerName: "Payment Type",
      field: "description",
      cellRendererFramework: ({ data }) => {
        return `${convertToNormalCaseRemoveNumber(data.description)}`;
      }
    },

    {
      headerName: "Payment Mode",
      field: "modeOfPayment",
      cellEditor: "agSelectCellEditor",
      editable: ({ data }) => data.chequeStatus === 'paid' ? false : true,
      cellRendererFramework: ({ data }) => convertToNormalCase(data.modeOfPayment),
      cellEditorParams: ({ data }) => {
        var modeOfPayment = data.modeOfPayment;
        let PaymentModeOptionsTemp = PaymentModeOptions.filter(paymentModeOption => paymentModeOption !== modeOfPayment)

        PaymentModeOptionsTemp.unshift(modeOfPayment)
        PaymentModeOptionsTemp = PaymentModeOptionsTemp.map(paymentModeOption => convertToNormalCase(paymentModeOption))

        return { values: PaymentModeOptionsTemp }
      },
    },
    {
      headerName: "Bank Name",
      field: "bankName",
      cellRendererFramework: ({ data }) => {
        return `${convertToNormalCase(data.bankName)}`;
      }
    },
    {
      headerName: "Payment Ref",
      field: "documentNo",
      cellRendererFramework: ({ data }) => data.documentNo
    },
    {
      headerName: "Paid Date",
      field: "chequeDate",
      cellRendererFramework: ({ data }) => {
        return data?.chequeDate ? dateByformat(data.chequeDate, "DD-MM-YYYY") : dateByformat(data.dueDate, "DD-MM-YYYY"); //for bug 8458
      },
    },
    {
      headerName: "Amount",
      field: "actualAmount",
      minWidth: 240,
      cellRendererFramework: ({ data }) => {
        return (<div className="annualRent">{currencyFormat(data?.totalAmount)}</div>)
      },
    },
    {
      headerName: "Payment Status",
      field: "chequeStatus",
      editable: ({ data }) => data.chequeStatus === 'paid' ? false : true,
      cellEditor: "agSelectCellEditor",
      cellRendererFramework: viewStatusRender,
      cellEditorParams: ({ data }) => {
        if (data?.modeOfPayment?.toLowerCase() === 'cheque') {
          if (data.chequeStatus?.toLowerCase() === 'bounce') {
            return {
              values: ["select", "paid", "cancelled"],
            }
          } else {
            return {
              values: ["select", "pending", "paid", "bounce", "cancelled"],
            }
          }
        } else {
          return {
            values: ["select", "pending", "paid", "cancelled"],
          }
        }
      },
    },
    {
      headerName: "Reason",
      field: "reason",
      hide: (status !== 'bounce' && status !== 'cancelled'),
      cellRendererFramework: ({ data }) => data.reason === 'select' ? '--' : convertToNormalCase(data.reason)
    },
    {
      headerName: "Action",
      cellRendererFramework: viewRender,
      cellStyle: { textAlign: "center" },
      maxWidth: 100,
      filter: false,
       pinned:'right',
    },
  ];

  const PDCApi = useSelector((state: any) => state.contracts.pdcList);

  const properties = useSelector((state: any) => state.dashboard.propertiesDrop); 

  useEffect(() => {

    if (query.get("pdcId")) {
      ; (async () => {
        setIsRequestLoading(true)
        const pdc = await getOnePDC(query.get("pdcId"))
        if (pdc?.status === 200 && pdc?.data?.result?.creditCardRequest === "pending") {
          setShowAll(false)
          setVisible({ status: true, index: 1 });
        }
      })()
    }

  }, [query.get("pdcId")])

  const convertToNormalCase = (input = '') => {
    // Check if the input is in camel case
    if (input?.indexOf('_') === -1 && input !== input?.toLowerCase()) {
      return input?.replace(/([a-z])([A-Z])/g, '$1 $2')?.replace(/\b\w/g, char => char.toUpperCase());
    }

    // Check if the input is in snake case
    if (input?.indexOf('_') !== -1) {
      return input?.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
    }

    return input?.charAt(0).toUpperCase() + input?.slice(1); // Already in normal case
  }

  const convertToNormalCaseRemoveNumber = (input = '') => {
    // Remove numbers if the input consists of any number
    if (/^\d+$/.test(input)) {
      return '';
    }

    // Remove numbers from the input
    input = input.replace(/\d+/g, '');

    // Check if the input is in camel case
    if (input.indexOf('_') === -1 && input !== input.toLowerCase()) {
      return input.replace(/([a-z])([A-Z])/g, '$1 $2');
    }

    // Check if the input is in snake case
    if (input.indexOf('_') !== -1) {
      return input.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
    }

    return input.charAt(0).toUpperCase() + input.slice(1); // Already in normal case
  }

  const defaultPropertyOpt = {
    label: "All Properties",
    value: ""
  }

  const defaultUnitOpt = {
    label: "All Units",
    value: ""
  }

  const viewPaymentAttachment = (item) => {
    setSelectedPDC(item);
    setVisible({ status: true, index: 0 });
  };

  const [propertyOptions, setPropertyOptions] = useState([
    {
      name: "property",
      label: "Property",
      search: true,
      initialValue: "",
      options: [defaultPropertyOpt],
    },
    {
      name: "unit",
      label: "Unit",
      search: true,
      initialValue: "",
      options: [],
    },
    {
      name: "status",
      label: "Payment Status",
      initialValue: "pending",
      options: paymentStatus,
    },
    {
      name: "Payment Mode",
      label: "Payment Modes",
      initialValue: "All",
      options: paymentModes,
    },
    {
      name: "Payment Type",
      label: "Payment Type",
      initialValue: "All",
      options: paymentTypes,
    },
  ]);

  
  useEffect(() => {

    if (PDCApi?.data?.length > 0) {
      setPdcList(PDCApi?.data);
    } else {
      setPdcList(null);
    }
  }, [PDCApi, status]);

  useEffect(() => {
    if (properties.length === 0) {
      dispatch(getPropertiesDrop());
    } else {
      setPropertyOptions([
        {
          name: "property",
          label: "Property",
          search: true,
          initialValue: "",
          options: [defaultPropertyOpt, ...properties],
        },
        {
          name: "unit",
          label: "Unit",
          search: true,
          initialValue: "",
          options: [defaultUnitOpt],
        },
        {
          name: "status",
          label: "Payment Status",
          initialValue: "pending",
          options: paymentStatus,
        },
        {
          name: "Payment Mode",
          label: "Payment Modes",
          initialValue: "All",
          options: paymentModes,
        },
        {
          name: "Payment Type",
          label: "Payment Type",
          initialValue: "All",
          options: paymentTypes,
        },
      ]);
    }
  }, [properties]);

  useEffect(() => {

    dispatch(getPropertyUnits(selectedProperty?.propertyId, buId, true));

    return () => dispatch(emptyPropertyUnits());
  }, [selectedProperty, buId]);



  const getPDCUnitList = () => {
    setPdcList(null);
    
    setIsLoading(true)
    let params = {};
    if (selectedProperty?.propertyId)
      params["propertyId"] = selectedProperty?.propertyId;
    if (selectedUnit)
      params["unitId"] = selectedUnit;
    if (status)
      params["chequeStatus"] = status;
    if (buId)
      params["businessUnitId"] = buId;
    if (selectPaymentMode?.value)
      params["paymentMode"] = selectPaymentMode?.value;
    if (selectPaymentType?.value)
      params["paymentType"] = selectPaymentMode?.value;
    if(exportData)
      params["exportData"] = exportData;
      params['totalCount'] = PDCApi?.totalCount;
    dispatch(
      getPDC(
        params,
        limit,
        page,
        setIsLoading,
        setExportData,
      )
    );
    getPendingCreditCardRequests()
  }

  useEffect(() => {
    getPDCUnitList()
  }, [exportData]);

  const getPendingCreditCardRequests = async () => {
    try {
      const url = `${leaseService}/booking/pdclist?creditCardRequest=pending`;

      const response = await axios.get(url);


      if (response.status === 200) {
        setSelectedPendingPDC(response?.data?.result?.data);
        setIsRequestLoading(false)
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    setPdcList(null);
    setIsLoading(true)
    setPage(1);
    let params = {};
    if (selectedProperty?.propertyId)
      params["propertyId"] = selectedProperty?.propertyId;
    if (selectedUnit)
      params["unitId"] = selectedUnit;
    if (status)
      params["chequeStatus"] = status;
    if (buId)
      params["businessUnitId"] = buId;
    if (selectPaymentMode?.value)
      params["paymentMode"] = selectPaymentMode?.value;
    if (selectPaymentType?.value)
      params["paymentType"] = selectPaymentType?.value;
    dispatch(
      getPDC(
        params,
        limit,
        1,
        setIsLoading,
        setExportData,
      )
    );
    // }
  }, [selectedProperty, selectedUnit, status, buId, selectPaymentMode, selectPaymentType]);

  useEffect(() => {


    let finalUnitOptions = units && units.length > 0 ? units : []
    setPropertyOptions([
      {
        name: "property",
        label: "Property",
        search: true,
        initialValue: "",
        options: [defaultPropertyOpt, ...properties],
      },
      {
        name: "unit",
        label: "Unit",
        search: true,
        initialValue: "",
        options: [defaultUnitOpt, ...finalUnitOptions],
      },
      {
        name: "status",
        label: "Payment Status",
        options: paymentStatus,
        initialValue: "pending",

      },
      {
        name: "Payment Mode",
        label: "Payment Modes",
        initialValue: "All",
        options: paymentModes,
      },
      {
        name: "Payment Type",
        label: "Payment Type",
        initialValue: "All",
        options: paymentTypes,
      },
    ]);
  }, [units, pdcList]);





  const downLoadDocument = async (id) => {

    const url = `${leaseService}/document/getDocument/${id}?preview=true`;

    try {
      const response = await axios.get(url, { headers: { 'bussiness-unit': 'AUTO', 'accept': '*/*', 'organization-unit': 'AGP', 'channel': 'MOBILE', 'accept-language': 'EN', 'source': 'Orion' } })

      let { data } = response;

      var link = document.createElement('a');
      link.target = "_blank";
      link.href = data?.result?.location;
      link.dispatchEvent(new MouseEvent('click'));

    } catch (error) {

    }
  }

  function viewStatusRender(record) {
    if (record.data.chequeStatus === 'paid') {
      return <>
        {convertToNormalCase(record.data.chequeStatus)}
        {
          record.data.paymentReceiptId && (
            <Button
              onClick={() => {
                downLoadDocument(record.data.paymentReceiptId)
              }}
              type="link"
              className="ag-primary"
            >{` - Rec: ${record?.data?.receiptNo}` || 'Download receipt'}
            </Button>
          )
        }
      </>
    } else {
      return convertToNormalCase(record.data.chequeStatus)
    }
  }

  function viewRender(record) {


    return (
      record.data._id && (
        <>
          <Button
            icon={<EditOutlined />}
            onClick={() => {
              setPaymentStatusInfo({ paymentMode: record.data?.modeOfPayment, params: record, isNew: true })
              setVisible({ status: true, index: 2 });
            }}
            type="link"
            className="ag-primary"

          />
        </>
      )
    );
  }




  function handlePropertyChange(property, label) {

    let unit = selectedUnit;
    let selectedStatus = status;
    if (label === "property") {
      setUpdateValue([{ label: 'unit', value: "" }, { label: 'status', value: "pending" }]);
      setSelectedUnit(null);
      setStatus("pending");
      setSelectedProperty({
        propertyId: property?.value,
        property_name: property?.label,
      });
    } else if (label === "unit" && property?.label !== null) {
      unit = units[property?.value];

      setSelectedUnit(property?.label === 'All Units' ? '' : property?.value);
    } else if (label === "status" && property?.value !== null) {
      selectedStatus = property?.value === 'all' ? null : property?.value;
      setStatus(selectedStatus);
    } else if (label === "Payment Mode") {
      property?.value === "all" ? setPaymentMode(null) : setPaymentMode({ value: property?.value, label: property?.label });
    } else if (label === "Payment Type") {
      property?.value === "all" ? setPaymentType(null) : setPaymentType({ value: property?.value, label: property?.label })
    }
  }

  const getPaymentMode = (key) => {
    let temp = ''
    switch (key) {
      case 'Cheque':
        temp = 'cheque'
        break;
      case 'Credit Card':
        temp = 'credit_card'
        break;
      case 'Bank Transfer':
        temp = 'bank_transfer'
        break;
      case 'Cash':
        temp = 'cash'
        break;
      default:
        temp = key
        break;
    }

    return temp
  }

  const onUpdateCheckStatus = async (params) => {
    setIsLoading(true)
    try {
      if (params.value !== 'select') {

        if (
          (params.data?.modeOfPayment?.toLowerCase() === 'cheque') &&
          (params.value === 'bounce' || params.value === 'cancelled' || params.value === 'paid')
        ) {

          setPaymentStatusInfo({ paymentMode: params.data?.modeOfPayment, status: params.value, params: params })
          setVisible({ status: true, index: 2 });

          return;
        }
        await updatePDCStatus(
          {
            leaseId: params.data.leaseId,
            pdcId: params.data._id,
          },
          { [params.colDef.field]: getPaymentMode(params.value) }
        );

        // to refresh the PDC records again

        let urlParams = {};
        if (selectedProperty?.propertyId)
          urlParams["propertyId"] = selectedProperty?.propertyId;
        if (selectedUnit)
          urlParams["unitId"] = selectedUnit;
        if (status)
          urlParams["chequeStatus"] = status;
        if (buId)
          params["businessUnitId"] = buId;
        if (selectPaymentMode?.value)
          params["paymentMode"] = selectPaymentMode?.value;
        if (selectPaymentType?.value)
          params["paymentType"] = selectPaymentMode?.value;
        dispatch(
          getPDC(
            urlParams,
            limit,
            page,
        setIsLoading,
        setExportData,
          )
        );
        getPendingCreditCardRequests()
      }
    } catch (error) {
     console.log(error)
    }
  };

  const handlePagination = (page) => {
    setIsLoading(true)
    setPdcList(null);
    setPage(page);
    let params = {};
    if (selectedProperty?.propertyId)
      params["propertyId"] = selectedProperty?.propertyId;
    if (selectedUnit)
      params["unitId"] = selectedUnit;
    if (status)
      params["chequeStatus"] = status;
    if (buId)
      params["businessUnitId"] = buId;
    if (selectPaymentMode?.value)
      params["paymentMode"] = selectPaymentMode?.value;
    if (selectPaymentType?.value)
      params["paymentType"] = selectPaymentType?.value;
    dispatch(
      getPDC(
        params,
        limit,
        page,
        setIsLoading,
        setExportData,
      )
    );
  };

  const onUpdate = () => {
    setPdcList(null);
    setIsLoading(true)
    let params = {};
    if (selectedProperty?.propertyId)
      params["propertyId"] = selectedProperty?.propertyId;
    if (selectedUnit)
      params["unitId"] = selectedUnit;
    if (status)
      params["chequeStatus"] = status;
    if (buId)
      params["businessUnitId"] = buId;
    if (selectPaymentMode?.value)
      params["paymentMode"] = selectPaymentMode?.value;
    if (selectPaymentType?.value)
      params["paymentType"] = selectPaymentMode?.value;
    dispatch(
      getPDC(
        params,
        limit,
        page,
        setIsLoading,
        setExportData,
      )
    );
  }

  const getPDCList = () => {

    setIsLoading(true);
    let params = {};
    if (selectedProperty?.propertyId)
      params["propertyId"] = selectedProperty?.propertyId;
    if (selectedUnit)
      params["unitId"] = selectedUnit;
    if (status)
      params["chequeStatus"] = status;
    if (buId)
      params["businessUnitId"] = buId;
    if (selectPaymentMode?.value)
      params["paymentMode"] = selectPaymentMode?.value;
    if (selectPaymentType?.value)
      params["paymentType"] = selectPaymentMode?.value;
    dispatch(
      getPDC(
        params,
        limit,
        page,
        setIsLoading,
        setExportData,
        
      )
    );
  }

  const onUpdatePDC = (close = false) => {
    getPDCList()
    getPendingCreditCardRequests()
    setShowAll(true)
    setVisible({ status: false, index: 0 })

  }

  const onCancel = () => {
    getPDCList()
    setVisible({ status: false, index: 0 })
  }

  const openModalAction = (data) => {
    setPaymentStatusInfo(data);
    setVisible({ status: true, index: 2 });
  }

  const popup = [
    {
      title: (
        <Title level={3} className=" mb-0">
          Attachment
        </Title>
      ),
      content: (
        <ViewPayment
          item={selectedPDCs}
          onUpdate={onUpdate}
        />
      ),
      width: 650,
    },
    {
      title: (
        <Title level={3} className=" mb-0">
          Credit Card Payment Request(s)
        </Title>
      ),
      content: (
        <CreditCardRequest
          list={selectedPendingPDCs}
          isLoading={isRequestLoading}
          showAll={showAll}
          setIsRequestLoading={setIsRequestLoading}
          onUpdate={onUpdatePDC}
          getAllPayments={() => {
            getPDCList()
          }}
        />
      ),
      width: 1650,
    },
    {
      title: (
        <Title level={3} className=" mb-0">
          Update Payment
        </Title>
      ),
      content: (
        <ModifyPaymentStatus
          data={paymentStatusInfo}
          onUpdate={onUpdatePDC}
          onCancel={onCancel}
          getAllPayments={() => {
            getPDCList()
          }}
          openModalAction={openModalAction}
        />
      ),
      width: 850,
    },
  ];

  const popupProps = {
    closable: true,
    visibility: visible.status,
    title: popup[visible.index].title,
    content: popup[visible.index].content,
    width: popup[visible.index].width,
    onCancel: () => {
      getPDCList()
      setVisible({ status: false, index: 0 });
      setSelectedPDC(null)
      setShowAll(true)
      navigate(`/payments`)
    }
  };

const downloadAction = ()=>{
  setExportData(true)
}


  return (
    <>
      <DashboardLayout>
        <Row gutter={[32, 32]}>
          <Col span={24}>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <SectionHeader
                  heading="Payments"
                  total={PDCApi?.totalCount || 0}
                  headTopLeftText={selectedPendingPDCs?.length ? <Button danger type="text" onClick={() => setVisible({ status: true, index: 1 })}>
                    <InfoCircleOutlined /> {selectedPendingPDCs.length} pending requests
                  </Button> : null}
                  viewOption={propertyOptions}
                  justifyStart={true}
                  updateValue={updateValue}
                  handleView={handlePropertyChange}
                  noBorder={true}
                  viewAutoCompleteOptions={buOptions}
                  
                  downloadAction={downloadAction}
                  onAutocompleteSelect={(e) => {


                    if (e === 'All Landlords') {
                      if (selectedProperty != null || buId != null || selectedUnit != null) {
                        setSelectedProperty(null)
                        setBUId(null)
                        setSelectedUnit(null);
                        setUpdateValue([{ label: 'property', value: "" }, { label: 'unit', value: "" }, { label: 'status', value: "pending" }]);
                        dispatch(getPropertiesDrop());
                      }
                    }
                    else {
                      const code = buOptions[0].options.find(option => option.value === e)?.a
                      dispatch(getPropertiesDropBUID(code))
                      setBUId(code)
                      setSelectedProperty(null)
                      setSelectedUnit(null);
                      setUpdateValue([{ label: 'property', value: "" }, { label: 'unit', value: "" }, { label: 'status', value: "pending" }]);
                    }
                  }
                  }
                  filterOption={(inputValue, option) => {
                    if (inputValue === 'All Landlords') {
                      return option
                    } else {
                      return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    }
                  }
                  }
                  justifyAEnd={true}
                />
              </Col>

           { 
         
            <>
             <Col span={24}>
                <Pagination
                  className="ag-property-text-pagination"


                  total={PDCApi?.totalCount || 0}
                  showTotal={(total, range) =>
                    `Showing ${range[0]}-${range[1]} of ${total} Payments`
                  }

                  defaultCurrent={1}
                  pageSize={PDCApi?.perPage || 10}
                  showSizeChanger={false}
                  current={page}
                  onChange={(e) => handlePagination(e)}
                />
              </Col>

              <Col span={24}>
                <Row gutter={[24, 24]} justify="end">
                  <Col span={24}>
                   
                      <Card
                       bordered={false}
                       className="ag-nospace-body"
                       // extra={<SearchFelds />}
                     >
                    {  isLoading ?
                      <Loader/>
                        :
                    <Grid
                      ref={gridRef}
                      data={pdcList ? pdcList : []}
                      columns={pdcCol}
                      pagination={false}
                      defaultSettings={gridProps}
                      onCellValueChanged={onUpdateCheckStatus}
                      noRowlabel="Payments"
                      fullPage={true}
                      overlayNoRowsTemplate={
                        "Payments data cannot be found."
                      }
                    />
                    }
                    </Card>
                    
                     
                  </Col>
                
                </Row>
              </Col>
             
             </>
             }
            </Row>
          </Col>
        </Row>
      </DashboardLayout>
      <Popup destroyOnClose={true} {...popupProps} />
    </>
  );
};