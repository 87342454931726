import Axix from "../../../../../utils/axiosInceptor";
import axios from "../../../../../utils/axiosInceptor";
import * as action_types from "./constants";
import {
  maintenanceService,
  authService,
  leaseService,
  // ticketBaseUrl,
} from "../../../../../configs/constants";
import LocalStorageService from "../../../../../utils/localStorageService";
import { message } from "antd";

const ticketsUrlNew = `${maintenanceService}/maintenance/getTickets?`;
const singleTicketsUrlNew = `${maintenanceService}/maintenance/getTicket`;

// const ticketsUrlExport = `${ticketBaseUrl}/maintenance/export?`;
// const singleticketsUrlNew = `${ticketBaseUrl}/facilityManager/getTicket`;

const ticketsTypeUrl = `${maintenanceService}/maintenance/serviceprovider/building/category?`;
const ticketsSubTypeUrl = `${maintenanceService}/facilityManager/fetchTicketCategoryChildren`;
// const ticketsUrl = `${maintenanceService}/facilityManager/getTickets`;

const ticketsCountUrl = `${maintenanceService}/facilityManager/getTicketsCount`;

const propertyUrl = `${maintenanceService}/property/serviceProviderPropertyList`; //changed

const techUrl = `${maintenanceService}/maintenance/technician/eligibleTechnician`;

const technicianUrl = `${maintenanceService}/maintenance/technician`;

const maintananceUrl = `${maintenanceService}/maintenance`;

const availableUrl = `${maintenanceService}/maintenance/getAvailablity`;

const deviceIdUrl = `${authService}/user/device-token`;

const ticketsStatusTypeUrl = `${maintenanceService}/maintenance/getTickets`;

export const getTicketType = (param) => {
  return async (dispatch) => {
    try {
      let params = new URLSearchParams({
        ...param,
      });
      const {
        data: { result },
      } = await Axix.get(ticketsTypeUrl + params.toString());
      dispatch({
        type: action_types.TICKETS_TYPE,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getTicketSubType = (param) => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await Axix.post(ticketsSubTypeUrl, { category_id: param });
      dispatch({
        type: action_types.TICKETS_SUB_TYPE,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getAvailableTechnicians = async (params) => {
  return await Axix.post(`${technicianUrl}/available`, params);
};

export const assignAvailableTechnicians = async (technicianId, params) => {
  return await Axix.patch(`${maintananceUrl}/ticketReassign/${technicianId}`, params);
};

export const getNewTicket = (token, page, param = {}) => {
  return async (dispatch) => {
    try {
      let params = new URLSearchParams({
        ticketStatus: "new",
        page: page,
        ...param,
      });
      const {
        data: { result },
      } = await Axix.get(ticketsUrlNew + params.toString());
      dispatch({
        type: action_types.TICKETS_NEW,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getInprogressTicket = (token, page, param = {}) => {
  return async (dispatch) => {
    try {
      let params = new URLSearchParams({
        ticketStatus: "inProgress",
        acknowledged: "no",
        page: page,
        ...param,
      });
      const {
        data: { result },
      } = await Axix.get(ticketsUrlNew + params.toString());
      dispatch({
        type: action_types.TICKETS_PROGRESS,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getAcknowledgedTicket = (token, page, param = {}) => {

  return async (dispatch) => {
    try {
      let params = new URLSearchParams({
        ticketStatus: "inProgress",
        acknowledged: "yes",
        page: page,
        ...param,
      });
      const {
        data: { result },
      } = await Axix.get(ticketsUrlNew + params.toString());
      dispatch({
        type: action_types.TICKETS_ACKNOWLEDGED,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getClosedTicket = (token, page, param = {}) => {
  return async (dispatch) => {
    try {
      let params = new URLSearchParams({
        ticketStatus: "completed",
        page: page,
        ...param,
      });
      const {
        data: { result },
      } = await Axix.get(ticketsUrlNew + params.toString());
      dispatch({
        type: action_types.TICKETS_CLOSED,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const exportTickets = (token, page, param = {}) => {
  return async (dispatch) => {
    try {
      let params = new URLSearchParams({
        ticketStatus: "completed",
        page: page,
        ...param,
      });
      const {
        data: { result },
      } = await Axix.get(ticketsUrlNew + params.toString());
      dispatch({
        type: action_types.TICKETS_CLOSED,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

// export const getNewCount = (param = {}) => {
//   return async (dispatch) => {
//     try {
//       const {
//         data: { result },
//       } = await Axix.get(`${ticketsCountUrl}?status=new`, { params: param });

//       dispatch({
//         type: action_types.TICKETS_COUNT_NEW,
//         data: result,
//       });
//     } catch (e) {
//       console.log("Err", e);
//     }
//   };
// };

export const getInprogressCount = (param = {}) => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await Axix.get(`${ticketsCountUrl}?status=inProgress`, {
        params: param,
      });
      dispatch({
        type: action_types.TICKETS_COUNT_PROGRESS,
        data: result,
      });
    } catch (e) {
      console.log("Errr", e);
    }
  };
};

export const getClosedCount = (param = {}) => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await Axix.get(`${ticketsCountUrl}?status=completed`, {
        params: param,
      });
      dispatch({
        type: action_types.TICKETS_COUNT_CLOSED,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getNewTicketStatusType = () => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await Axix.get(`${ticketsStatusTypeUrl}?ticketStatus=new`);
      dispatch({
        type: action_types.TICKETS_NEW_STATUS_TYPE,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getProgressTicketStatusType = () => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await Axix.get(`${ticketsStatusTypeUrl}?status=inProgress`);
      dispatch({
        type: action_types.TICKETS_PROGRESS_STATUS_TYPE,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getClosedTicketStatusType = () => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await Axix.get(`${ticketsStatusTypeUrl}?status=completed`);
      dispatch({
        type: action_types.TICKETS_CLOSED_STATUS_TYPE,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getPropertyName = () => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await Axix.get(propertyUrl);
      dispatch({
        type: action_types.PROPERTY_NAME,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getTechnicians = (body) => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await Axix.post(techUrl, body);
      dispatch({
        type: action_types.ELIGIBLE_TECHNICIAN,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};


export const getAvailablity = (body) => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await Axix.get(availableUrl, {
        params: body
      });
      dispatch({
        type: action_types.AVAILABLE_SLOTS,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
      message.error(e?.response?.data?.error);
      dispatch({
        type: action_types.AVAILABLE_SLOTS,
        data: [],
      });
    }
  };
};

// maintenance/getAvailablity?propertyId=propertyId-f9a5a80b-e9bc-4938-be14-1ccb5c9f0f3e
// &categoryId=categoryId-552f6758-c9db-43e7-ac29-905158859d53
// &timeInMin=30
// &startDate=2023-04-07'

export const resetTechnician = () => {
  return (dispatch) => {
    dispatch({
      type: action_types.RESET_TECHNICIAN,
      data: [],
    });
  };
};

export const getDeviceToken = () => {
  return async (dispatch) => {
    try {
      const body = {
        token: LocalStorageService.getAccessToken(),
      };
      const {
        data: { result },
      } = await Axix.post(deviceIdUrl, body);
      dispatch({
        type: action_types.GET_DEVICEID_TOKEN,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

// export const getPresignedImage = (mediaArray) => {
//   return async (dispatch) => {
//     try {
//       const result = await apiIterator(mediaArray);
//       console.log('I AM A RESULTER', result);
//       dispatch({
//         type: action_types.PRESIGNED_IMAGE,
//         data: result,
//       });
//     } catch (e) {
//       console.log("Err", e);
//     }
//   };
// };
// documentId_ebcec933-d641-48b3-ad72-807b0510cb36
//  '/documentId_ebcec933-d641-48b3-ad72-807b0510cb36?expiresIn=600' 
// organization-unit: AGP' \   -H 'bussiness-unit: AUTO' \   -H 'channel: MOBILE' \   -H 'accept-language: EN' \   -H 'source: Orion

//   if (config.url.includes('ms/document')) 
// {      config.headers['accept-language'] = 'EN'; 
//      config.headers['source'] = Config.SOURCE;   
//    config.headers['channel'] = Config.CHANNEL;    
//   config.headers['accept'] = 'application/json';    
//   config.headers['Content-Type'] = 'multipart/form-data'; 
//      config.headers['bussiness-unit'] = Config.BUSINESS_UNIT; 
//      config.headers['organization-unit'] = Config.ORGANIZATION_UNIT;  
//   }

// SOURCE = Orion
// ENV = production
// CHANNEL = MOBILE
// APP_NAME = tenantApp
// BUSINESS_UNIT = AUTO
// ORGANIZATION_UNIT = AGP
// MEDIA_URL = https://document-api.agp-dev.com/
// BASE_URL = https://eqarcom-gateway-api.agp-dev.com/api/v1/



export const getTicketReviews = (param) => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await Axix.get(
        `${maintenanceService}/facilityManager/getTicketReviews`,
        { params: { caseId: param } },
      );
      dispatch({
        type: action_types.TICKETS_REVIEWS,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getTicketByID = (id) => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await Axix.get(`${singleTicketsUrlNew}/${id}`);
      dispatch({
        type: action_types.TICKET_BY_ID,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);

    };
  }
};


export const getOrgByID = (orgId) => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await Axix.get(`${maintenanceService}/organization/${orgId}`); ///organization/orgId-eb9dccfb-325e-4c5f-a306-7842789f75fd'
      dispatch({
        type: action_types.ORG_BY_ID,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);

    };
  }
};

export const getPropertyUnits = (property_id) => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await Axix.get( 
        `${maintenanceService}/unit/unitDropDown/${property_id}`,
      );
      dispatch({
        type: action_types.GET_PROPERTY_UNITS,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const emptyTickets = () => {
  return (dispatch) => {
    dispatch({
      type: action_types.EMPTY_TICKETS,
      data: null,
    });
  };
};
//k

export const getTicketsExport = (token, param = {}) => {
  return async (dispatch) => {
    //   try {
    //     let data = {};

    //     await fetch(
    //       ticketsUrlExport +
    //         new URLSearchParams({
    //           partyId: LocalStorageService.getPartyId(),
    //           ...param,
    //         }),
    //       {
    //         method: "GET",
    //         headers: {
    //           Authorization: `${token?.token}`,
    //         },
    //       },
    //     )
    //       .then((response) => response.json())
    //       .then((rest) => {
    //         data = rest.result;
    //       });
    //     dispatch({
    //       type: action_types.TICKETS_EXPORT,
    //       data: data,
    //     });
    //   } catch (e) {
    //     console.log("Err", e);
    //   }
  };
};

// export const getTicketsExport = (param) => {
//   return async (dispatch) => {
//     try {
//       const {
//         data: { result },
//       } = await Axix.get(`${maintenanceService}/maintenance/export`, param);
//       dispatch({
//         type: action_types.TICKETS_EXPORT,
//         data: result,
//       });
//     } catch (e) {
//       console.log("Err", e);
//     }
//   };
// };
